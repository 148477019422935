import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Pascohero from "../components/hero/Pascohero";
import { FaSistrix } from "react-icons/fa";

function Pasco() {
  return (
    <Layout>
      <Pascohero />
      <Container>
        <Row>
          <Col lg={6} className="fifty-split-1">
            <h3>PASCO COUNTY</h3>
            <p>
              At the southern tip of the Nature Coast and the northern edge of
              Tampa Bay, Pasco County is an outdoor lover’s paradise that offers
              a great eco-tourism and outdoor adventure alternative to the
              typical theme-park experience. Close to major cities, the area
              boasts 20 miles of shoreline to the west, hilly terrain to the
              east, and more than 100,000 acres of pristine wilderness to
              explore in between.
            </p>
            <p>
              Due to the fact that Pasco County still had available land for
              building unlike it’s sister county of Pinellas, a building boom is
              now going on and new communities are popping up all over this
              beautiful county, from the Gulf of Mexico to the outer tips of
              Hillsborough. Land O’Lakes, Odessa, Trinity, Lutz, Hudson, Wesley
              Chapel and New Port Richey are seeing unprecedented growth with
              housing developments for both young families and retirees! We
              specialize in both existing and new construction phase
              inspections.
            </p>
          </Col>
          <Col lg={6} className="fifty-split-2">
            <h5>General Faqs</h5>
            <ul>
              <li>
                <FaSistrix /> Population: 553,947 (2019 census)
              </li>
              <li>
                <FaSistrix /> Founded: June 2, 1887
              </li>
              <li>
                <FaSistrix /> County Seat: Dade City
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Container className="mt-30">
        <Row className="mb-30">
          <h3>Service Locations for Pasco County</h3>
        </Row>
        <Row>
          <Col lg={6} className="fifty-split-1">
            <ul>
              <li>
                <FaSistrix /> New Port Richey
              </li>
              <li>
                <FaSistrix /> Hudson
              </li>
              <li>
                <FaSistrix /> Port Richey
              </li>
              <li>
                <FaSistrix /> Holiday
              </li>
              <li>
                <FaSistrix /> Land O' Lakes
              </li>
              <li>
                <FaSistrix /> Odessa
              </li>
              <li>
                <FaSistrix /> Zephryhills
              </li>
              <li>
                <FaSistrix /> Dade City
              </li>
              <li>
                <FaSistrix /> Wesley Chapel
              </li>
              <li>
                <FaSistrix /> San Antonio
              </li>
            </ul>
          </Col>
          <Col lg={6} className="fifty-split-2">
            <a
              href="/static/179c0ea4b7d3d7c90b6e3e04f5f6ef49/45338/pascomap.jpg"
              target="blank"
            >
              <StaticImage src="../images/pascomap.jpg" />
            </a>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default Pasco;
