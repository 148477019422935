import React from "react";
import { Container } from "react-bootstrap";

const Pascohero = () => {
  return (
    <div className="overlay-hero banner banner-page banner-pasco mb-30">
      <Container>
        <div className="overlay-herotxt">
          <h2 className="banner-head ">Pasco County</h2>
        </div>
      </Container>
    </div>
  );
};

export default Pascohero;
